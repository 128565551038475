





























import Vue from 'vue';

export default Vue.extend({
  name: 'GsPriceDisplay',
  props: {
    value: { type: Object, default: () => ({}) as {totalGrossPrice?: number, unitGrossPrice?: number, currency: string} },
    loading: { type: Boolean, default: false },
    prominent: { type: Boolean, default: false },
  },
  data: () => ({

  }),
  computed: {
    priceDisplay(): string | undefined {
      const price = this.value?.totalGrossPrice ?? this.value?.unitGrossPrice;
      return this.$format.currency(price, this.value.currency);
    },
  },
});
