// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/shop-payment-schema',
  type: 'object',
  required: ['id', 'shopOrderId', 'paymentDate', 'amount'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    shopOrderId: {
      type: 'integer',
    },
    paymentDate: {
      type: 'string',
      format: 'date-time',
    },
    paymentMethod: {
      enum: [
        1,
        2,
      ],
    },
    onlinePaymentMethod: {
      enum: [
        0,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
      ],
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    amount: {
      type: 'number',
    },
    transactionId: {
      type: 'string',
      nullable: true,
    },
    paymentStatus: {
      enum: [
        1,
        2,
        3,
      ],
    },
    paymentMessage: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
