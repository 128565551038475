













import Vue from 'vue';
import LookupLocationsRequestViewModel from '@/src/services/v1/viewModel/request/Locations/LookupLocationsRequestViewModel';
import Int64LookupResourceViewModel from '@/src/services/v1/viewModel/resource/Int64LookupResourceViewModel';

export default Vue.extend({
  props: {
    value: { default: undefined, type: undefined },
    disabled: { default: false, type: Boolean },
    readonly: { default: false, type: Boolean },
    solo: { default: false, type: Boolean },
    placeholder: { default: undefined, type: String },
    label: { default: undefined, type: String },
  },
  data: () => ({
    search: undefined,
    items: [] as Int64LookupResourceViewModel[],
  }),
  computed: {
    computedLabel(): string {
      return this.label ?? this.$t('form.address.plz');
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        await this.performLookup(undefined, this.value);
        if (this._.isSet(this.value)) {
          const location = await this.$service.api.locations.getLocationById(this.value);
          this.$emit('select', location);
        }
      },
    },
  },
  methods: {
    async performLookup(search?: string, locationId?: number) {
      const query = new LookupLocationsRequestViewModel().fromModel({
        'paging.CurrentPage': 1,
        'paging.PageSize': 10,
        searchCriteria: search,
        id: locationId,
      });
      const list = await this.$service.api.locations.lookupLocations(query);
      this.items = list.items;
    },
    onSearch(value: string) {
      // TODO: p-lookup should do this itself, but it does not debounce properly
      this.$debounce(() => {
        this.performLookup(value);
      }, 300, this)();
    },
  },
});
