
















import GetSuppliersLookupRequestViewModel from '@/src/services/v1/viewModel/request/ShopSuppliers/GetSuppliersLookupRequestViewModel';
import Int64LookupResourceViewModel from '@/src/services/v1/viewModel/resource/Int64LookupResourceViewModel';
import Vue from 'vue';

export default Vue.extend({
  name: 'GsShopSupplierLookup',
  props: {
    value: { type: undefined, default: undefined },
    label: { type: String, default: undefined },
    clearable: { type: Boolean, default: false },
  },
  data: () => ({
    items: [] as Int64LookupResourceViewModel[],
    errorMessage: undefined as undefined | string,
    isLoading: false,
    filter: new GetSuppliersLookupRequestViewModel(),
  }),
  mounted() {
    this.populateData();
  },
  methods: {
    onSearch(searchCriteria: string) {
      this.filter.searchCriteria = searchCriteria;
      this.populateData();
    },
    async populateData() {
      this.isLoading = true;
      try {
        const response = await this.$service.api.shopSuppliers.getSuppliersLookup(this.filter);
        this.items = response.items;
      } catch (error: any) {
        this.errorMessage = error?.message ?? error;
      }
      this.isLoading = false;
    },
  },
});
