











































import Vue from 'vue';
import RequestSummaryResourceDTO from '../services/v1/generated/types/dto/resource/RequestSummaryResourceDTO';

export default Vue.extend({
  props: {
    value: { type: Object, default: () => ({}) },
    asTable: { type: Boolean, default: false },
    withAccordions: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  data: () => ({
    accordionsExpaned: [],
  }),
  computed: {
    summary() {
      const summaryModel = (this.value as any)?.summary ?? this.value;
      const getDTO = summaryModel?.getDTO as ()=>any;
      const summary = getDTO ? getDTO.call(summaryModel) : this.value;
      return summary as RequestSummaryResourceDTO;
    },
  },
});
