






























































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: () => undefined },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    firstNameDisabled: { type: Boolean, default: undefined },
    lastNameDisabled: { type: Boolean, default: undefined },
    salutationDisabled: { type: Boolean, default: undefined },
    emailDisabled: { type: Boolean, default: undefined },
    isDeliveryAddress: { type: Boolean, default: false },
    isNameRequired: { type: Boolean, default: false },
  },
  data: () => ({
    legalFormTypes: [] as any,
    phoneInvalid: false,
    mobilePhoneInvalid: false,
    errorResponse: undefined as any,
  }),
  computed: {
    innerValue: {
      get(): any {
        return this.value?.address ?? this.value;
      },
      set(value: any) {
        this.$emit('input', value);
      },
    },
    isNameValidationRequired: {
      get(): boolean {
        if (this.isDeliveryAddress) {
          return !this._.isSet(this.innerValue.company) || this.isNameRequired;
        }
        return true;
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const value = this.value as any;
        this.phoneInvalid = !this.$format.phoneValid(value.phone);
        if (!this.phoneInvalid)value.phone = this.$format.phoneData(value.phone)
        this.mobilePhoneInvalid = !this.$format.phoneValid(value.mobilePhone);
        if (!this.mobilePhoneInvalid)value.mobilePhone = this.$format.phoneData(value.mobilePhone)
        this.$withoutWatchers(() => {
          if (value && !this.disabled && !this.readonly) {
            value.languageIso2 = value.languageIso2 ?? Vue.$translation.get();
            if (value.address) {
              value.address.languageIso2 = value.address.languageIso2 ?? Vue.$translation.get();
            }
          }
        });
      },
    },
  },
  async beforeMount() {
    if (this.innerValue) {
      this.innerValue.isCompany = false;
    }
  },
});
