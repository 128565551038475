











































import Vue from 'vue';
import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';

export default Vue.extend({
  mixins: [validatable],
  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    value: { type: undefined, default: undefined },
    valueOne: { type: undefined, default: true },
    valueTwo: { type: undefined, default: false },
    captionOne: { type: String, default: 'app.yes' },
    captionTwo: { type: String, default: 'app.no' },
    removeOptionOne: { type: undefined, default: false },
    removeOptionTwo: { type: undefined, default: false },
    disableOptionOne: { type: Boolean, default: false },
    disableOptionTwo: { type: Boolean, default: false },
  },
  data: () => ({
    oneChecked: false,
    twoChecked: false,
  }),
  computed: {
    computedValue: {
      get(): any {
        return this.value;
      },
      set(value: any): void {
        this.$emit('input', value);
      },
    },
    groupName(): string {
      // eslint-disable-next-line no-underscore-dangle
      const label = `${this.label}_${(this as any)._uid.toString()}`;
      return label.replace(/\s/g, '-');
    },
  },
  watch: {
    removeOptionOne: {
      immediate: true,
      handler(newValue: boolean) {
        if (newValue) {
          this.$emit('input', this.valueTwo);
        }
      },
    },
    removeOptionTwo: {
      immediate: true,
      handler(newValue: boolean) {
        if (newValue) {
          this.$emit('input', this.valueOne);
        }
      },
    },
    value: {
      immediate: true,
      handler() {
        if (this.value === this.valueOne) {
          this.oneChecked = true;
          this.twoChecked = false;
        } else if (this.value === this.valueTwo) {
          this.oneChecked = false;
          this.twoChecked = true;
        }
      },
    },
  },
});
