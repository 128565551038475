
























































































































































































import Vue, { PropType } from 'vue';
import User from '@glittr/frontend-core/src/plugins/auth/user';
import MembershipModel from '../../services/v1/viewModel/resource/MembershipResourceViewModel';

export default Vue.extend({
  auth: true,
  props: {
    hasBackButton: { type: Boolean, default: false },
    navigation: { type: Array as PropType<{caption: string, to: string, icon: string}[]>, default: () => [] },
  },
  data: () => ({
    membership: undefined as MembershipModel | undefined,
    date: Vue.$date.now().format('YYYY-MM-DD'),
    profileMenuVisible: false,
    isSidedrawerVisible: Vue.$media.isNotMobile,
    isSettingsVisible: false,
    isNavMinifed: false,
    isMembershipDataLoading: false,
  }),
  computed: {
    user(): User {
      return this.$auth.user ?? ({} as User);
    },
    isHostAdmin(): boolean {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('HostAdmin');
      }
      return isAdmin;
    },
    isAdmin(): boolean {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
      }
      return isAdmin;
    },
    isPwaButtonVisible(): boolean {
      return !!Vue.$store.values.showPwaInstallPromptFunction;
    },
    username(): string {
      if (this.isMembershipDataLoading) {
        return '…';
      }
      if (this.hasMembership && this.membership) {
        const owner = this.membership.memberContact;
        if (owner.address.isCompany) {
          return `${owner.address.companyName}`;
        }
        return `${owner.address.firstName} ${owner.address.lastName}`;
      }
      return this.$auth.user?.userName ?? '';
    },
    membershipNumber(): string {
      if (this.isMembershipDataLoading) {
        return '…';
      }
      if (this.hasMembership && this.membership) {
        return `${this.membership.membershipNumber}`;
      }
      return this.$t('app.noMembership');
    },
    hasMembership(): boolean {
      return this.$service.api.memberships.getIsMember();
    },
  },
  watch: {
    isNavMinifed() {
      this.$sessionStorage.set('isNavMinifed', this.isNavMinifed);
    },
  },
  beforeMount() {
    if (this.$media.isNotMobile) {
      this.isSidedrawerVisible = this.$sessionStorage.get<boolean>('isSidedrawerVisible') ?? true;
      this.isSettingsVisible = this.isAdmin && (this.$sessionStorage.get<boolean>('isSettingsVisible') ?? false);
      this.isNavMinifed = this.$sessionStorage.get<boolean>('isNavMinifed') ?? false;
    }
  },
  async mounted() {
    await this.populateMembershipData();
    // PWA
    const addBtn = document.querySelector('.pwa-add-button') as HTMLElement;
    if (addBtn && Vue.$store.values.showPwaInstallPromptFunction) {
      addBtn.addEventListener('click', Vue.$store.values.showPwaInstallPromptFunction);
    }
  },
  methods: {
    openHomepage() {
      window.open('https://gastrosuisse.ch', '_blank');
    },
    toggleSettings() {
      this.isSettingsVisible = !this.isSettingsVisible;
      if (this.$media.isMobile) {
        this.toggleSideDrawerVisible(false);
      } else {
        this.isNavMinifed = this.isSettingsVisible;
      }
      this.$sessionStorage.set('isSettingsVisible', this.isSettingsVisible);
    },
    navigationClicked() {
      if (this.$media.isMobile) {
        this.toggleSideDrawerVisible(false);
      }
    },
    settingsClicked() {
      if (this.$media.isMobile) {
        this.toggleSettings();
      }
    },
    async doLogoff() {
      this.$router.push(this.$config.values['auth-redirects-logout']);
    },
    async populateMembershipData() {
      this.isMembershipDataLoading = true;
      this.membership = await this.$service.api.memberships.getMyMembership();
      this.isMembershipDataLoading = false;
    },
    toggleSideDrawerVisible(isVisible?: boolean) {
      const newVisibleState = isVisible ?? !this.isSidedrawerVisible;
      this.isSidedrawerVisible = newVisibleState;
      this.$sessionStorage.set('isSidedrawerVisible', this.isSidedrawerVisible);
    },
  },
});
