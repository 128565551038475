









import Vue from 'vue';

export default Vue.extend({
  props: {
    hasBackButton: { type: Boolean, default: false },
  },
  data: () => ({
  }),
  computed: {
    navigationList() {
      if (Vue.$config.values['is-shop-enabled']) {
        return [
          { icon: 'home', caption: this.$t('dashboard.page.title'), to: '/gs/' },
          { icon: 'shopping_cart', caption: this.$t('shop.page.title'), to: '/gs/shop/articles' },
          // { icon: 'public', caption: this.$t('memberPortal.gastrosuisse.page.title'), to: 'https://gastrosuisse.ch' },
        ];
      }
      return [
        { icon: 'home', caption: this.$t('dashboard.page.title'), to: '/gs/' },
        // { icon: 'public', caption: this.$t('memberPortal.gastrosuisse.page.title'), to: 'https://gastrosuisse.ch' },
      ];
    },
  },
});
