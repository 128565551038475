































import ShopCategoriesListResourceViewModel from '@/src/services/v1/viewModel/resource/ShopCategoriesListResourceViewModel';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'GsShopCategoryEntry',
  props: {
    value: { type: Object as PropType<ShopCategoriesListResourceViewModel>, default: () => new ShopCategoriesListResourceViewModel() },
  },
  data: () => ({
    selected: undefined as any,
  }),
  computed: {
    isAdmin(): boolean {
      return this.$auth.isInRole('Admin', 'HostAdmin');
    },
  },
  methods: {
    gotoEditCategory(id: number | string | undefined | null) {
      this.$router.push(`/gs/shop/categories/${id}/edit`);
    },
    onCategoryClick(category: any) {
      this.$emit('click', category);
    },
  },
});
