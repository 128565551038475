






import Vue from 'vue';
import layoutNoLogin from './layout-no-login.vue';
import layoutHasLogin from './layout-has-login.vue';

export default Vue.extend({
  name: 'LayoutDefault',
  props: {},
  data: () => ({
    layoutComponent: undefined as any,
  }),
  async beforeMount() {
    this.layoutComponent = this.importLayout();
  },
  methods: {
    importLayout() {
      if (Vue.$auth.isLoggedIn) {
        return layoutHasLogin;
      }
      return layoutNoLogin;
    },
  },
});
