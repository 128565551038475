









import Vue from 'vue';

export default Vue.extend({
  props: {
    hasBackButton: { type: Boolean, default: false },
  },
  data: () => ({
  }),
  computed: {
    navigationList() {
      if (Vue.$config.values['is-shop-enabled']) {
        return [
          { icon: 'person', caption: this.$t('memberPortal.memberData.page.title'), to: '/gs/memberPortal/member-data' },
          { icon: 'gavel', caption: this.$t('memberPortal.legalService.page.title'), to: '/gs/memberPortal/legal-service' },
          { icon: 'local_library', caption: this.$t('memberPortal.gastroJournal.page.title'), to: '/gs/memberPortal/gastro-journal' },
          { icon: 'import_contacts', caption: this.$t('memberPortal.branchenKnowHow.page.title'), to: '/gs/memberPortal/branchen-know-how' },
          { icon: 'star_half', caption: this.$t('memberPortal.hotelClassification.page.title'), to: '/gs/memberPortal/hotel-classification' },
          { icon: 'shopping_cart', caption: this.$t('shop.page.title'), to: '/gs/shop/articles' },
          { icon: 'support', caption: this.$t('memberPortal.support.page.title'), to: '/gs/memberPortal/support' },
          // { icon: 'public', caption: this.$t('memberPortal.gastrosuisse.page.title'), to: 'https://gastrosuisse.ch' },
        ];
      }
      return [
        { icon: 'person', caption: this.$t('memberPortal.memberData.page.title'), to: '/gs/memberPortal/member-data' },
        { icon: 'gavel', caption: this.$t('memberPortal.legalService.page.title'), to: '/gs/memberPortal/legal-service' },
        { icon: 'local_library', caption: this.$t('memberPortal.gastroJournal.page.title'), to: '/gs/memberPortal/gastro-journal' },
        { icon: 'import_contacts', caption: this.$t('memberPortal.branchenKnowHow.page.title'), to: '/gs/memberPortal/branchen-know-how' },
        { icon: 'star_half', caption: this.$t('memberPortal.hotelClassification.page.title'), to: '/gs/memberPortal/hotel-classification' },
        { icon: 'support', caption: this.$t('memberPortal.support.page.title'), to: '/gs/memberPortal/support' },
        // { icon: 'public', caption: this.$t('memberPortal.gastrosuisse.page.title'), to: 'https://gastrosuisse.ch' },
      ];
    },
  },
});
