










































import Vue from 'vue';
import { StepData } from './gs-wizard.vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    loading: { type: Boolean, default: false },
    proceedDisabled: { type: Boolean, default: false },
    proceedLabel: { type: String, default: undefined },
    processingLabel: { type: String, default: undefined },
    customProceedFunction: { type: Function, default: undefined },
    customBackFunction: { type: Function, default: undefined },
    backDisabled: { type: Boolean, default: undefined },
  },
  data: () => ({
    stepData: {} as Partial<StepData>,
    // eslint-disable-next-line no-unused-vars
    proceedFunction: undefined as undefined | (() => void),
    validateFunction: undefined as undefined | (()=>boolean),
    backFunction: undefined as undefined | (() => void),
    // eslint-disable-next-line no-unused-vars
    completeFunction: undefined as undefined | (() => void),
    isLastStep: false,
    isFirstStep: false,
    errorResponse: undefined,
    internalLoading: false,
  }),
  computed: {
    isLoading: {
      get(): boolean {
        return this.internalLoading || this.loading;
      },
      set(value: boolean) {
        this.internalLoading = value;
      },
    },
    isBackDisabled(): boolean {
      if (this.backDisabled !== undefined) {
        return this.backDisabled;
      }

      if (this.isLastStep || this.isFirstStep) {
        return true;
      }

      return false;
    },
    proceedLabelComputed(): string {
      const defaultLabel = this.isLastStep ? this.$t('app.saveAndComplete') : this.$t('app.saveAndContinue');
      return this.proceedLabel ?? defaultLabel;
    },
  },
  methods: {
    goBack() {
      if (typeof this.customBackFunction === 'function') {
        this.customBackFunction();
      } else if (this.backFunction) {
        this.backFunction();
      }
    },
    async proceedOrComplete() {
      if (this.isLastStep) {
        await this.complete();
      } else {
        await this.proceed();
      }
    },
    async proceed() {
      if (typeof this.customProceedFunction === 'function') {
        this.isLoading = true;
        try {
          const result = this.customProceedFunction();
          if (this._.isPromise(result)) {
            await result;
          }
        } catch (error: any) {
          this.$log.error(error);
          this.errorResponse = error;
        }
        this.isLoading = false;
      } else if (typeof this.proceedFunction === 'function') {
        this.proceedFunction();
      }
    },
    async complete() {
      if (typeof this.customProceedFunction === 'function') {
        this.isLoading = true;
        try {
          const result = this.customProceedFunction();
          if (this._.isPromise(result)) {
            await result;
          }
        } catch (error: any) {
          this.$log.error(error);
          this.errorResponse = error;
        }
        this.isLoading = false;
      } else if (typeof this.completeFunction === 'function') {
        this.completeFunction();
      }
    },
  },
});
