























import Vue from 'vue';

export default Vue.extend({
  name: 'GsBasketButton',
  props: {
    dense: { type: Boolean, default: false },
    spacious: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    totalQuantity: 0,
    isLoading: false,
  }),
  beforeMount() {
    this.populateData();
    this.$eventbus.on('BASKETCHANGE', this.populateData);
  },
  beforeDestroy() {
    this.$eventbus.off('BASKETCHANGE', this.populateData);
  },
  methods: {
    async populateData() {
      this.isLoading = true;
      const response = await this.$service.api.shopBasket.getMiniBasket();
      this.totalQuantity = response.itemsTotalQuantity!;
      this.isLoading = false;
    },
  },
});
