
















































































import Vue, { PropType } from 'vue';
import ShopArticleResourceViewModel from '@/src/services/v1/viewModel/resource/ShopArticleResourceViewModel';
import GetShopArticlePriceRequestViewModel from '@/src/services/v1/viewModel/request/ShopArticles/GetShopArticlePriceRequestViewModel';
import ShopTaxedPriceResourceViewModel from '@/src/services/v1/viewModel/resource/ShopTaxedPriceResourceViewModel';
import gsAddToBasket from './gs-add-to-basket.vue';

export default Vue.extend({
  name: 'GsArticleItemDetail',
  components: { gsAddToBasket },
  props: {
    value: { type: Object as PropType<ShopArticleResourceViewModel>, default: () => new ShopArticleResourceViewModel() },
  },
  data: () => ({
    price: new ShopTaxedPriceResourceViewModel(),
    isPriceLoading: false,
  }),
  computed: {
    isAdmin(): boolean {
      return this.$auth.isInRole('Admin', 'Hostadmin');
    },
  },
  watch: {
    'value.id': {
      immediate: true,
      handler() {
        if (this._.isSet(this.value.id)) {
          this.$debounce(() => {
            this.loadPrice();
          }, 50, this)();
        }
      },
    },
  },
  methods: {
    async loadPrice() {
      this.isPriceLoading = true;
      const req = new GetShopArticlePriceRequestViewModel().fromModel({
        userId: this.$auth.user?.id ?? undefined,
      });
      this.price = await this.$service.api.shopArticles.getShopArticlePrice(this.value.id!, req);
      this.isPriceLoading = false;
    },
  },
});
