































import Vue, { PropType } from 'vue';
import ShopArticleResourceViewModel from '@/src/services/v1/viewModel/resource/ShopArticleResourceViewModel';

export default Vue.extend({
  name: 'GsArticleItem',
  props: {
    value: { type: Object as PropType<ShopArticleResourceViewModel>, default: () => new ShopArticleResourceViewModel() },
  },
  data: () => ({

  }),
  computed: {
    isAdmin(): boolean {
      return this.$auth.isInRole('Admin', 'HostAdmin');
    },
    articleDescription(): string | undefined {
      return this.value.shortDescription ?? this.value.description ?? undefined;
    },
  },
  methods: {
    gotoArticleEdit() {
      this.$router.push(`/gs/shop/articles/${this.value.id}/edit`);
    },
    gotoArticleDetail() {
      this.$router.push(`/gs/shop/articles/${this.value.id}`);
    },
  },
});
