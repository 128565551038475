// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SetPaymentRequestModel from '../../model/set-payment-request-model';
import SetPaymentRequestDTO from '../dto/set-payment-request-dto';
import dtoSchema from '../schemas/set-payment-request-schema';

export default abstract class SetPaymentRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SetPaymentRequestDTO>) {
    super();
    if (data) {
      this.transactionId = data.transactionId ?? undefined;
      this.amount = data.amount;
      this.currency = data.currency ?? undefined;
      this.paymentMethod = data.paymentMethod;
      this.onlinePaymentMethod = data.onlinePaymentMethod;
      this.userId = data.userId;
    }
  }
  /**
  */
  transactionId?: string;
  /**
  * @type {double}
  */
  amount?: number;
  /**
  */
  currency?: string;
  /**
  * @type {int64}
  */
  paymentMethod?: 1 | 2; // ShopPaymentMethodDTO
  /**
  * @type {int64}
  */
  onlinePaymentMethod?: 0 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14; // ShopOnlinePaymentMethodDTO
  /**
  * @type {int64}
  */
  userId?: number;

  static toModel(dto: DeepPartial<SetPaymentRequestDTO>): SetPaymentRequestModel;
  static toModel(dto: DeepPartial<SetPaymentRequestDTO> | undefined | null): SetPaymentRequestModel | undefined;
  static toModel(dto: DeepPartial<SetPaymentRequestDTO> | undefined | null): SetPaymentRequestModel | undefined {
    return dto ? new SetPaymentRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SetPaymentRequestModel> | SetPaymentRequestModel): SetPaymentRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      transactionId: unwrapped.transactionId,
      amount: unwrapped.amount,
      currency: unwrapped.currency,
      paymentMethod: unwrapped.paymentMethod,
      onlinePaymentMethod: unwrapped.onlinePaymentMethod,
      userId: unwrapped.userId,
    } as SetPaymentRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for SetPaymentRequestModel, can not map to SetPaymentRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
