// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateShopPaymentRequestModel from '../../model/create-shop-payment-request-model';
import CreateShopPaymentRequestDTO from '../dto/create-shop-payment-request-dto';
import dtoSchema from '../schemas/create-shop-payment-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class CreateShopPaymentRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateShopPaymentRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.shopOrderId = data.shopOrderId;
      this.paymentDate = data.paymentDate;
      this.paymentMethod = data.paymentMethod;
      this.onlinePaymentMethod = data.onlinePaymentMethod;
      this.currency = data.currency ?? undefined;
      this.amount = data.amount;
      this.transactionId = data.transactionId ?? undefined;
      this.paymentStatus = data.paymentStatus;
      this.paymentMessage = data.paymentMessage ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  shopOrderId?: number;
  /**
  * @type {date-time}
  */
  paymentDate?: string;
  /**
  * @type {int64}
  */
  paymentMethod?: 1 | 2; // ShopPaymentMethodDTO
  /**
  * @type {int64}
  */
  onlinePaymentMethod?: 0 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14; // ShopOnlinePaymentMethodDTO
  /**
  */
  currency?: string;
  /**
  * @type {double}
  */
  amount?: number;
  /**
  */
  transactionId?: string;
  /**
  * @type {int32}
  */
  paymentStatus?: 1 | 2 | 3; // ShopPaymentStatusDTO
  /**
  */
  paymentMessage?: string;

  static toModel(dto: DeepPartial<CreateShopPaymentRequestDTO>): CreateShopPaymentRequestModel;
  static toModel(dto: DeepPartial<CreateShopPaymentRequestDTO> | undefined | null): CreateShopPaymentRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateShopPaymentRequestDTO> | undefined | null): CreateShopPaymentRequestModel | undefined {
    return dto ? new CreateShopPaymentRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateShopPaymentRequestModel> | CreateShopPaymentRequestModel): CreateShopPaymentRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      shopOrderId: unwrapped.shopOrderId,
      paymentDate: unwrapped.paymentDate,
      paymentMethod: unwrapped.paymentMethod,
      onlinePaymentMethod: unwrapped.onlinePaymentMethod,
      currency: unwrapped.currency,
      amount: unwrapped.amount,
      transactionId: unwrapped.transactionId,
      paymentStatus: unwrapped.paymentStatus,
      paymentMessage: unwrapped.paymentMessage,
    } as CreateShopPaymentRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateShopPaymentRequestModel, can not map to CreateShopPaymentRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
