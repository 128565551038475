



































import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import _ from '@glittr/frontend-core/src/utils';
import Vue, { PropType } from 'vue';

type TranslatableModel = BaseModel<any> & {translatedLanguage_ISO2: string};

export default Vue.extend({
  name: 'GsTranslationField',
  props: {
    value: { type: Object as PropType<TranslatableModel>, default: undefined },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
  }),
  computed: {
    selectedLanguage: {
      get(): string | undefined {
        return this.value?.translatedLanguage_ISO2;
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value && this.value.translatedLanguage_ISO2 === undefined) {
          const [defaultLang] = this.$translation.availableAppLanguages;
          this.onLanguageSelected(defaultLang);
        }
      },
    },
  },
  methods: {
    async onLanguageSelected(selectedLang: string) {
      if (!_.isSet(this.value)) {
        // Do nothing... there is no model to change
        return;
      }
      let confirmed = true;
      if (this.value.isDirty) {
        confirmed = await this.$dialog.confirm(this.$t('app.unsavedConfirmTitle'), this.$t('app.unsavedConfirmMessage'));
      }
      if (confirmed) {
        // Don't mutate prop directly
        const model = _.deepClone(this.value);
        model.translatedLanguage_ISO2 = selectedLang.toLowerCase();
        // Re-initialize model
        model.fromModel(model);
        this.$emit('input', model);
        this.$emit('language-select', model.translatedLanguage_ISO2);
      }
    },
  },
});
