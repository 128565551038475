































































import ShopBasketItemsListResourceViewModel from '@/src/services/v1/viewModel/resource/ShopBasketItemsListResourceViewModel';
import UpdateShopBasketItemRequestViewModel from '@/src/services/v1/viewModel/resource/UpdateShopBasketItemRequestViewModel';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'GsBasketItem',
  props: {
    value: { type: Object as PropType<ShopBasketItemsListResourceViewModel>, default: () => new ShopBasketItemsListResourceViewModel() },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    quantity: 0,
    errorChangeQuantity: undefined as any,
    isLoading: false,
  }),
  computed: {
    articleRoute() {
      return `/gs/shop/articles/${this.value.shopArticleId}`;
    },
  },
  watch: {
    'value.quantity': {
      immediate: true,
      handler() {
        this.quantity = this.value.quantity ?? 0;
      },
    },
    quantity() {
      this.$debounce(() => {
        this.changeQuantity(this.quantity);
      }, 600, this)();
    },
  },
  methods: {
    async changeQuantity(quantity: number) {
      this.isLoading = true;
      try {
        const model = new UpdateShopBasketItemRequestViewModel();
        model.articleId = this.value.shopArticleId!;
        model.quantity = quantity;

        await this.$service.api.shopBasket.updateBasketItem(model);
        this.$emit('input', new ShopBasketItemsListResourceViewModel().fromDTO({ ...this.value.toDTO(), quantity }));
      } catch (error: any) {
        this.errorChangeQuantity = error?.message ?? error;
      }
      this.isLoading = false;
    },
  },
});
