




































import Vue, { PropType } from 'vue';
import ShopCategoriesListResourceListResultViewModel from '@/src/services/v1/viewModel/resource/ShopCategoriesListResourceListResultViewModel';
import ShopCategoriesListResourceViewModel from '@/src/services/v1/viewModel/resource/ShopCategoriesListResourceViewModel';

export default Vue.extend({
  name: 'GsShopCategories',
  props: {
    value: { type: Object as PropType<ShopCategoriesListResourceListResultViewModel>, default: () => new ShopCategoriesListResourceListResultViewModel() },
    visible: { type: Boolean, default: false },
    overlay: { type: Boolean, default: false },
    selectedCategory: { type: [String, Number], default: undefined },
  },
  data: () => ({
    selection: undefined as any,
  }),
  computed: {
    isAdmin(): boolean {
      return this.$auth.isInRole('admin', 'hostadmin');
    },
    isOverlay(): boolean {
      return this.$media.isTablet || this.$media.isMobile;
    },
  },
  beforeMount() {
    this.$emit('update:visible', !this.isOverlay);
  },
  methods: {
    gotoCreateCategory() {
      this.$router.push('/gs/shop/categories/create');
    },
    onCategoryClick(category: ShopCategoriesListResourceViewModel) {
      if (this.selection === undefined) {
        this.$emit('update:selected-category', undefined);
      } else {
        this.$emit('update:selected-category', category.id);
      }
      if (this.isOverlay) {
        this.$emit('update:visible', false);
      }
    },
  },
});
