
















import Vue from 'vue';

export default Vue.extend({
  name: 'GsRemoveFromCart',
  props: {
    dense: { type: Boolean, default: false },
    spacious: { type: Boolean, default: false },
    articleId: { type: Number, default: undefined },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    async removeFromBasket() {
      this.isLoading = true;
      await this.$service.api.shopBasket.removeFromBasket(this.articleId!);
      this.isLoading = false;
    },
  },
});
