













import Vue from 'vue';
import LookupStatesRequestViewModel from '@/src/services/v1/viewModel/request/Locations/LookupStatesRequestViewModel';
import LookupModel from '../../services/v1/viewModel/resource/Int64LookupResourceViewModel';

export default Vue.extend({
  props: {
    value: { default: undefined, type: undefined },
    disabled: { default: false, type: Boolean },
    readonly: { default: false, type: Boolean },
    solo: { default: false, type: Boolean },
    placeholder: { default: undefined, type: String },
    label: { default: undefined, type: String },
  },
  data: () => ({
    selected: undefined,
    search: undefined,
    items: [] as LookupModel[],
  }),
  computed: {
    computedLabel(): string {
      return this.label ?? this.$t('form.address.state');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.performLookup(undefined, this.value as any);
        this.$set(this, 'selected', this.value);
      },
    },
  },
  methods: {
    async performLookup(searchCriteria?: string, id?: number) {
      const query = new LookupStatesRequestViewModel().fromModel({
        'paging.CurrentPage': 1,
        'paging.PageSize': 10,
        searchCriteria,
        id: id!,
      });
      const list = await this.$service.api.locations.lookupStates(query);
      this.items = list.items;
    },
    onSearch(value: string) {
      // TODO: p-lookup should do this itself, but it does not debounce properly
      this.$debounce(() => {
        this.performLookup(value);
      }, 300, this)();
    },
  },
});
