






import Vue from 'vue';

export default Vue.extend({
  name: 'GsShopCategoryItem',
  props: {

  },
  data: () => ({

  }),
});
