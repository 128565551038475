














import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { default: undefined, type: undefined },
    disabled: { default: false, type: Boolean },
    readonly: { default: false, type: Boolean },
    solo: { default: false, type: Boolean },
    placeholder: { default: undefined, type: String },
    label: { default: undefined, type: String },
    required: { default: false, type: Boolean },
  },
  data: () => ({
    items: [
      { caption: 'CH', id: 'CH' },
    ],
  }),
  mounted() {
    if (!this.value) {
      this.$emit('input', 'CH');
    }
  },
});
