


















































































































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: () => undefined },
    readonly: { type: Boolean, default: false },
    companyNameLabel: { type: String, default: undefined },
    hasPersonalInfo: { type: Boolean, default: false },
    hasWebsite: { type: Boolean, default: false },
    hasCompany: { type: Boolean, default: false },
    hasCompanyExtended: { type: Boolean, default: false },
    hasBirthdate: { type: Boolean, default: false },
    hasEmail: { type: Boolean, default: false },
    hasTelephone: { type: Boolean, default: false },
    hasMobile: { type: Boolean, default: false },
    emailRequired: { type: Boolean, default: false },
    companyNameRequired: { type: Boolean, default: false },
    birthdateRequired: { type: Boolean, default: false },
    disabledEmail: { type: Boolean, default: false },
    disablePostalCode: { type: Boolean, default: false },
    disableCity: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    legalFormTypes: [] as any,
    errorResponse: undefined as any,
  }),
  computed: {
    computedCompanyNameLabel(): string {
      return this.companyNameLabel ?? this.$t('form.address.company');
    },
    innerValue: {
      get(): any {
        return this.value?.address ?? this.value;
      },
      set(value: any) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const value = this.value as any;
        this.$withoutWatchers(() => {
          if (value && !this.disabled && !this.readonly) {
            value.languageIso2 = value.languageIso2 ?? Vue.$translation.get();
            if (value.address) {
              value.address.languageIso2 = value.address.languageIso2 ?? Vue.$translation.get();
            }
          }
        });
      },
    },
  },
  async beforeMount() {
    try {
      const list = await this.$service.api.attributes.getLegalFormTypes();
      this.legalFormTypes = list.items;
    } catch (error) {
      this.errorResponse = error;
    }
  },
});
