







































import Vue from 'vue';
import GetIssuuDocumentsRequestModel from '../services/v2/model/get-issuu-documents-request-model';
import GetIssuuEmbedDocumentRequestModel from '../services/v2/model/get-issuu-embed-document-request-model';

export default Vue.extend({
  props: {
    value: { type: String, default: undefined },
    pageSize: { type: Number, default: 30 },

    showToolbar: { type: Boolean, default: false },
    writeToQuery: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    isLoadingMore: false,
    currentLoadedIndex: 0,
    listOfPublications: [] as any[],
    archiveVisible: false,
    issuId: undefined as undefined | string,
    embedHTMLCode: undefined as undefined | string,
    selectedPublication: undefined,
  }),
  computed: {
    currentLanguage(): string {
      return this.$translation.get();
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.loadIssuById(this.value);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    const allIssuEmbeds = await this.loadMore();
    if (!this.value) {
      const [latestIssuEmbed] = allIssuEmbeds;
      const latestIssuId = latestIssuEmbed?.data.id;
      this.onPublicationChanged(latestIssuId);
    } else if (this.issuId !== this.value) this.embedHTMLCode = await this.loadembedHTMLCode(this.value);
    this.isLoading = false;
  },
  methods: {
    async loadMore() {
      this.isLoadingMore = true;
      const issuuDocumentsRequest = new GetIssuuDocumentsRequestModel();
      issuuDocumentsRequest.pageSize = this.pageSize;
      issuuDocumentsRequest.startIndex = this.currentLoadedIndex / this.pageSize;
      const res = await this.$service.v2.api.issuuDocuments.getIssuuDocuments(issuuDocumentsRequest);
      this.listOfPublications = [
        ...this.listOfPublications,
        ...res.items,
      ];
      this.currentLoadedIndex += this.pageSize;
      this.isLoadingMore = false;
      return this.listOfPublications;
    },
    async onPublicationChanged(id: string) {
      this.archiveVisible = false;
      if (id && this.issuId !== id) this.embedHTMLCode = await this.loadembedHTMLCode(id);
      this.$emit('input', id);
    },
    async loadembedHTMLCode(id: string) {
      this.isLoadingMore = true;
      this.issuId = id;
      const req = new GetIssuuEmbedDocumentRequestModel();
      req.id = id;
      const res = await this.$service.v2.api.issuuDocuments.getIssuuEmbedDocument(req);
      this.isLoadingMore = false;
      return res.data;
    },
    async loadIssuById(id: string) {
      if (id && this.issuId !== id) this.embedHTMLCode = await this.loadembedHTMLCode(id);
      this.issuId = id;
      if (this.writeToQuery) {
        this.$routerUtils.updateQueryParams({ id });
      }
    },
  },
});
