






























































import ShopBasketItemsListResourceViewModel from '@/src/services/v1/viewModel/resource/ShopBasketItemsListResourceViewModel';
import ShopBasketResourceViewModel from '@/src/services/v1/viewModel/resource/ShopBasketResourceViewModel';
import Vue from 'vue';

export default Vue.extend({
  name: 'GsBasketSummary',
  props: {
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isSubtotal: { type: Boolean, default: false },
  },
  data: () => ({
    deliveryCost: new ShopBasketItemsListResourceViewModel(),
    errorResponse: undefined as any,
    isLoading: false,
    basket: new ShopBasketResourceViewModel(),
  }),
  computed: {
    totalCost() {
      return this.basket.items.reduce((prev: number, item) => (item.quantity ?? 0) + prev, 0);
    },
    hasItems() {
      return (this.basket?.items?.length ?? 0) > 0;
    },
  },
  beforeMount() {
    this.populateData();
    this.$eventbus.on('BASKETCHANGE', this.populateData);
  },
  beforeDestroy() {
    this.$eventbus.off('BASKETCHANGE', this.populateData);
  },
  methods: {
    gotoShop() {
      this.$router.push('/gs/shop/');
    },
    updateItemQuantity(item: ShopBasketItemsListResourceViewModel) {
      // Because you should not bind a variable inside a loop it must be updated like this
      this.basket.items.forEach((i) => {
        if (i.id === item.id) {
          i.quantity = item.quantity;
        }
      });
    },
    async populateData() {
      this.isLoading = true;
      try {
        this.basket = await this.$service.api.shopBasket.getBasket();
        this.deliveryCost = this.basket.items.find((i) => i.isDeliveryCost) ?? new ShopBasketItemsListResourceViewModel();
        this.basket.items = this.basket.items.filter((i) => !i.isDeliveryCost);
        this.$emit('input', this.basket);
      } catch (error: any) {
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
  },
});
